import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'

interface CrosslinksButtonProps {
  setState: () => unknown
}

const CrosslinksButton: React.FC<CrosslinksButtonProps> = ({
  setState,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()

    setState()
  }

  const { t } = useTranslation('translation')

  return (
    <Button
      className="cta_pes_button"
      color="primary"
      onClick={handleClick}
      variant="outlined"
    >
      <AutorenewIcon /> {t('Material aendern')}
    </Button>
  )
}

export default CrosslinksButton
