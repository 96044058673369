import { ProductMaterial } from "../../../../types"

export const getSizeFromProductName = (productName: string) => {
  const firstPart = productName.match(/\d+/g)?.[0]
  const secondPart = productName.match(/\d+/g)?.[1]

  return `${firstPart}${secondPart}`
}

const getColorByMaterialData = (reference: string, target: string) => {
  const keyWords = reference.split('_')

  const color = keyWords.reduce(
    (result, keyWord) => result.replace(keyWord, ''),
    target,
  )

  const colorWithoutUnderscores = color.match(/[a-zA-Z]+/g)

  return colorWithoutUnderscores ? colorWithoutUnderscores[0] : ''
}

const findVariantsByPriceAndSize = (
  variants: ProductMaterial[],
  priceCountry: string,
  chosenPrice?: number,
  chosenSize?: string,
) => {
  const areSizeAndPriceDefined = chosenPrice && chosenSize

  if (areSizeAndPriceDefined) {
    return variants.reduce((result: ProductMaterial[], variant, index) => {
      const hasChosenSize = getSizeFromProductName(variant.name) === chosenSize
      const hasChosenPrice = variant.price[priceCountry] >= chosenPrice

      if (hasChosenSize && hasChosenPrice) {
        return [...result, { ...variant, originalIndex: index }]
      } else {
        return result
      }
    }, [])
  } else {
    return []
  }
}

export const createVariants = (
  data: ProductMaterial[],
  priceCountry: string,
) => {
  const variantsInStock = data.filter(variant => !variant.out_of_stock)

  return {
    getInStock: () => variantsInStock,
    getInStockPerColor: (chosenPrice?: number, chosenSize?: string) => {
      const variantsByPriceAndSize = findVariantsByPriceAndSize(
        variantsInStock,
        priceCountry,
        chosenPrice,
        chosenSize,
      )

      return variantsByPriceAndSize.reduce((result, variant) => {
        const color = getColorByMaterialData(
          variant.materialgroup,
          variant.material,
        )

        const key = color?.trim() === '' ? variant.material : color

        return {
          ...result,
          [key]: variant,
        }
      }, {})
    },
  }
}
