import React from 'react'
import { ProductData } from '../../types'
import ProductPreview from './Preview'
import { CrosslinkProductType } from '../../utils/ProductUtils'

interface CrosslinksSameMotiveProps {
  productData: ProductData
  currentMaterial: string
  setOnClick: () => unknown
  display: CrosslinkProductType[]
}

const CrosslinksSameMotive = (props: CrosslinksSameMotiveProps) => {
  const display = props.display

  if (display.length == 0) {
    return <></>
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md">
            <h2>Auch verfügbar als</h2>
          </div>
        </div>
        <div className="row">
          {display
          .filter((p: CrosslinkProductType) => p.material !== props.currentMaterial)
          .map((p: CrosslinkProductType) => (
            <div className="col-6 col-sm-4" key={p.signature}>
              <ProductPreview
                hideMaterialSize={true}
                hidePrice={true}
                hideTitle={true}
                material={p.material}
                materials={p.materialData}
                productData={p.productData}
                setOnClick={props.setOnClick}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default CrosslinksSameMotive
