import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ProductMaterial } from '../../types'
import Edit from '../cms/Edit'
import {env} from '../../../environment'

interface MaterialTextProps {
  material: ProductMaterial
}

const magentoMapping: { [key: string]: number } = {
  poster_plain: 98,
  wooden: 376,
  canvas: 99,
  poster_black_plastic: 98,
  poster_white_plastic: 98,
  poster_schwarz: 103,
  poster_kupfer: 103,
  acryl: 1299,
  alu: 101,
  galleryprint: 373,
  cup: 1034,
  heart: 1034,
  emaille: 1034,
  plastic_small: 1034,
  plastic_big: 1034,
  case_premium_samsung: 100,
  case_premium_apple: 100,
  case_tough_samsung: 100,
  case_tough_apple: 100,
  deskcalendar: 319,
  wallcalendar: 319,
  foldingcalendar: 319,
  squarecalendar: 319,
  familyplaner: 319,
  ravensburger: 1674,
  fotopuzzle: 1675,
  'giftvoucher-pdf': 657,
  pillow: 102,
  magnet: 999,
  placemat: 998,
  shirt_woman_white: 460,
  shirt_woman_black: 460,
  shirt_man_white: 460,
  shirt_man_black: 460,
  hoodie_black: 616,
  hoodie_blue: 616,
  hoodie_gray: 616,
  sweater_black: 619,
  sweater_gray: 619,
  sweater_blue: 619,
  metalposter: 2000,
}

const materialGroupMapping: { [key: string]: number } = {
  advent: 582,
}

const subGroup: { [key: string]: string } = {
  poster_black_plastic: 'Plastikrahmen',
  poster_white_plastic: 'Plastikrahmen',
  poster_schwarz: 'Holzrahmen',
  poster_kupfer: 'Metallrahmen',

  cup: 'Keramik-Tasse',
  heart: 'Herztasse',
  emaille: 'Emaille-Tasse',
  plastic_small: 'Kleiner Kinderbecher aus Kunststoff',
  plastic_big: 'Kinderbecher aus Kunststoff',

  case_premium_samsung: 'Premium',
  case_premium_apple: 'Premium',
  case_tough_samsung: 'Tough',
  case_tough_apple: 'Tough',
  foldingcalendar: 'Klapp [size]',
  wallcalendar: '[size]',
  deskcalendar: 'tisch-[size]',
  squarecalendar: '[size]',
  familyplaner: 'family-[size]',
  self_fill: 'Selbstbefuell-[size]',
  ferrero: 'Ferrero-[size]',
  kinder: 'Kinder-[size]',
  ferrero_xxl: 'Ferrero-XXL-[size]',
  kinder_xxl: 'Kinder-XXL-[size]',
  ueei: 'Ü Ei-[size]',
  tonys: 'Tonys-[size]',
  ravensburger: 'Ravensburger ([size])',
}

export const MaterialText: React.FC<MaterialTextProps> = ({ material }) => {
  const data = useStaticQuery(graphql`
    query MyMaterialTextQuery {
      allCockpitMaterial {
        nodes {
          lang
          cockpitId
          sub_content {
            value {
              content {
                value {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
          description {
            value {
              childMarkdownRemark {
                html
              }
            }
          }
          magento_producttype {
            value
          }
          subgroup {
            value
          }
          magento_mapping {
            type
            value
          }
        }
      }
    }
  `)

  let materialsCmsData = null

  if (material !== undefined) {
    let search = ''

    if (typeof materialGroupMapping[material.materialgroup] !== 'undefined') {
      search = materialGroupMapping[material.materialgroup]
    } else if (typeof magentoMapping[material.material] !== 'undefined') {
      search = magentoMapping[material.material]
    } else {
      console.warn('Missing mapping for *' + material.material + '*')
    }

    if (search != '') {
      materialsCmsData = data.allCockpitMaterial.nodes.filter((node: any) => {
        if (node.lang != env.getLanguage()) {
          return false
        }

        let ok = node.magento_producttype.value == search

        if (typeof subGroup[material.material] !== 'undefined') {
          if (node.subgroup === null) {
            ok = false
          } else {
            let compareSubgroup = '' + subGroup[material.material]

            if (compareSubgroup.indexOf('[size]') >= 0) {
              compareSubgroup = compareSubgroup.replace(
                '[size]',
                '' + material.size,
              )
            }

            ok = ok && node.subgroup.value == compareSubgroup
          }
        }

        return ok
      })
    }
  } else {
    console.warn('Missing material!!!')
  }

  let html = ''

  let cockpitId = ''

  if (materialsCmsData !== null && materialsCmsData.length > 0) {
    const materialCms = materialsCmsData[0]
    cockpitId = materialCms.cockpitId
    html += materialCms.description.value.childMarkdownRemark.html

    if (materialCms.sub_content !== null) {
      materialCms.sub_content.value.map((node: any) => {
        html += node.content.value.childMarkdownRemark.html
      })
    }
  }

  return (
    <div className="row" style={{ paddingTop: `24px` }}>
      <Edit cockpitId={cockpitId} type="public_material_infos" />
      <div
        className="col-md-12"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  )
}
