import React, { useEffect, useState } from "react";
import { useProductData } from "../../lib/product_hooks";
import { useMaterialsData } from "../../lib/materials_hook";
import { useArtistsData } from "../../lib/artists_hook";
import { parseSku } from "./Configuration";
import { LinearProgress } from "@mui/material";
import { Helmet } from "react-helmet";
import { env } from '../../../environment'

export const withDataFetching = (WrappedComponent) => {
  return function DataFetchingWrapper(props) {
    const productInformation = parseSku(props.sku)
    const [loading, setLoading] = useState(true)

    const artistsData = useArtistsData()
    const materialsData = useMaterialsData()
    const productData = useProductData({
        material: props.material,
        id: ['' + productInformation.motiveId],
        ratio: props.ratio,
    })

    useEffect(() => {
        if (productData !== undefined && materialsData !== undefined && artistsData !== undefined && materialsData.length > 0) {
            setLoading(false)
        }
        else {
            setLoading(true)
        }
    }, [productData, materialsData, artistsData])

    const robots = env.getSeo() == '1' ? 'index, follow' : 'noindex, follow';

    if (loading) return (
        <>
          <Helmet>
            <meta content={robots} name="robots" />
          </Helmet>
          <>
            <meta content="artboxONE" itemProp="brand" />
            <meta content={''} itemProp="sku" />
          </>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <LinearProgress />
              </div>
            </div>
          </div>
        </>
      )

    return <WrappedComponent artistsData={artistsData} materialsData={materialsData} productData={productData} robots={robots} {...props} />
  }
}
  