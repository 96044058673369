import React from 'react'
import ProductConfiguration from '../components/product/Configuration'
import { mapSlug2InternalKey, parseHashUrl } from '../utils/UrlUtils'
import withLocation from '../components/generic/withLocation'
import queryString from 'query-string'
import { isBrowser } from '../utils/utils'

export interface ProductPageOptions {
  materialSlug: string
  productSlug?: string
  sku: string
  location: any
}

const ProductPage = (props: ProductPageOptions) => {
  const material = mapSlug2InternalKey(props.materialSlug)
  let productSlug = props.productSlug
  let ratio = undefined
  let customText = undefined
  let data = undefined

  if (props.location.hash !== '') {
    data = parseHashUrl(props.location.hash)
  } else {
    data = props.location.search
      ? queryString.parse(props.location.search)
      : undefined
  }

  if (data !== undefined) {
    if (typeof data.variant === 'string') {
      productSlug = data.variant
    }

    if (typeof data.ratio === 'string') {
      ratio = parseInt(data.ratio)
    }

    if (typeof data.text === 'string') {
      customText = decodeURI(data.text)
    }
  }

  let url = ''

  if (isBrowser()) {
    url = location && location.href ? location.href : ''
  }

  return (
    <ProductConfiguration
      customText={customText}
      material={material}
      productSlug={productSlug}
      ratio={ratio}
      sku={props.sku}
      url={url}
    />
  )
}

export default withLocation(ProductPage)
