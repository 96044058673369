import React, { useRef, useState } from 'react';
import { ProductMaterial, Artist, ParsedSku, ProductData } from '../../types';
import ProductConfigurator, { Crosstype } from './configurator/ProductConfigurator';
import Favorite from './Favorite';
import Fullscreen from './Fullscreen';
import { Button } from '@mui/material';
import ArtistLinks from './ArtistLinks';
import ArtboxoneCarousel from '../generic/ArtboxoneCarousel';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useTranslation } from 'react-i18next';
import ArtworksInsideCalendar from './ArtworksInsideCalendar';
import CrosslinksSameMotive from './CrosslinksSameMotive';
import { CrosslinkProductType } from '../../utils/ProductUtils';
import ConfigurationDialog from './configurator/Dialog';
import { getCartCountry, getPriceCountry } from '../../utils/CountrySelection';
import FluidProductImage from './FluidProductImage';

interface ProductShowcaseRowProps {
  crosslinkDisplayData: CrosslinkProductType[],
  imageUrls: string[], 
  productData: ProductData, 
  artistsToShow: Artist[], 
  materialName: string, 
  productInformation: ParsedSku, 
  filteredMaterialsData: ProductMaterial[],
  materials: Record<string, any>[],
  chosenMaterial: ProductMaterial,
  ambientImages: JSX.Element[],
}


export const ProductShowcaseRow = ({
  imageUrls,
  productData,
  artistsToShow,
  materialName,
  productInformation,
  filteredMaterialsData,
  materials,
  chosenMaterial,
  crosslinkDisplayData,
  ambientImages,
}: ProductShowcaseRowProps) => {

  const { t } = useTranslation('translation')
  const priceCountry = getPriceCountry(getCartCountry())

  const [fullscreen, setFullscreen] = useState(false)
  const [openUpSellingDialog, setOpenUpSellingDialog] = useState(false)

  const crossLinkRef = useRef<HTMLDivElement>(null)
  const [crosslinksSameMotiveVisible, setCrosslinksSameMotiveVisible] = useState(false)
  const setCrosslinkExpansionStateOn = () => {
    setCrosslinksSameMotiveVisible(true)

    if (crossLinkRef.current) {
      crossLinkRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  const setCrosslinkExpansionStateOff = () => {
    setCrosslinksSameMotiveVisible(false)
  }

  const crosstype: Crosstype = [
    'wallcalendar',
    'foldingcalendar',
    'deskcalendar',
    'squarecalendar',
    'familyplaner',
  ].includes(materialName) ? 'calendar' : 'wallart'

  const imageComponents: JSX.Element[] = getImageComponents(ambientImages, imageUrls, productData);

  const showCrosslinkButton = crosslinkDisplayData.length > 1 || crosstype == 'calendar'

  const IsConfigurationDialogOpenable = materials.length > 1
  const configurationDialogButtonText = t(chosenMaterial.out_of_stock ? 'Zur Zeit leider ausverkauft' : 'In den Warenkorb')
  const openConfiguratorDialogButton = IsConfigurationDialogOpenable ? (<Button
    className="cta_pes_button"
    color="primary"
    data-test="cta_pes_add2cart_button_upselling"
    onClick={() => {
      setOpenUpSellingDialog(true);
    }}
    variant="contained"
  >
    <AddShoppingCartIcon />
    <div>
      {configurationDialogButtonText}
    </div>
  </Button>) : <></>
  
  return (
  <>
    <div className="row">
      <div className={fullscreen === true ? 'col-md-12' : 'col-md-6'}>
        {imageComponents.length == 1 && imageComponents}
        {imageComponents.length > 1 && (
          <ArtboxoneCarousel
            key={'pes_carousel_' + (fullscreen ? 'f' : 'n')}
            plugins={['arrows']}
            showThumbs={true}
          >
            {imageComponents}
          </ArtboxoneCarousel>
        )}

        <ArtistLinks
          artists={artistsToShow}
          materialName={materialName} />
        <div className="d-flex justify-content-center">
          <Favorite
            material={materialName}
            motiveId={productInformation.motiveId}
            ratio={productData.ratio}
            show={true} />
          {imageUrls.length >= 1 && (
            <Fullscreen
              fullscreen={fullscreen}
              setFullscreen={setFullscreen} />
          )}
        </div>
      </div>
      <div
        className={fullscreen === true ? 'col-md-12' : 'col-md-6'}
        style={{ marginTop: `24px` }}
      >
        <ProductConfigurator
          IsConfigurationDialogOpenable={IsConfigurationDialogOpenable}
          crosstype={crosstype}
          filteredMaterials={filteredMaterialsData}
          material={materialName}
          openConfiguratorDialogButton={openConfiguratorDialogButton}
          productData={productData}
          productInformation={productInformation}
          setCrosslinkExpansionStateOn={setCrosslinkExpansionStateOn}
          showCrosslinkButton={showCrosslinkButton} />
      </div>
    </div>

    <div ref={crossLinkRef} />
    {crosslinksSameMotiveVisible === true &&
      crosstype !== 'calendar' && (
        <CrosslinksSameMotive
          currentMaterial={materialName}
          display={crosslinkDisplayData}
          productData={productData}
          setOnClick={setCrosslinkExpansionStateOff}
        />
      )}
    {crosslinksSameMotiveVisible === true &&
      crosstype === 'calendar' && (
        <ArtworksInsideCalendar
          currentMaterial={materialName}
          productData={productData}
          setOnClick={setCrosslinkExpansionStateOff}
        />
      )}
    
    {IsConfigurationDialogOpenable && (
            <ConfigurationDialog
              chosenMaterial={chosenMaterial}
              imageUrl={imageUrls[0]}
              materials={materials}
              open={openUpSellingDialog}
              priceCountry={priceCountry}
              productData={productData}
              setOpen={setOpenUpSellingDialog}
            />
          )}
    </>
  );
}

function getImageComponents(ambientImages: JSX.Element[], imageUrls: string[], productData: ProductData) {
  const imageComponents: JSX.Element[] = []
  imageUrls.forEach((imageUrl: string, i: number) => (
    imageComponents.push(
      <FluidProductImage
        className={"product_image"}
        eagerLoad={true}
        imageUrl={imageUrl}
        productData={productData}
      />
    )
  ))
  ambientImages.forEach((image: JSX.Element) => {
    imageComponents.push(image)
  })
  return imageComponents
}
