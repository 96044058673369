import React from 'react'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

interface ArtworksInsideButtonProps {
  setState: () => unknown
}

const ArtworksInsideButton: React.FC<ArtworksInsideButtonProps> = ({
  setState,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()

    setState()
  }

  const { t } = useTranslation('translation')

  return (
    <Button
      className="cta_pes_button"
      color="primary"
      onClick={handleClick}
      variant="outlined"
    >
      <PhotoLibraryIcon /> {t('Enthaltene Motive')}
    </Button>
  )
}

export default ArtworksInsideButton
