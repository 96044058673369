import { fetchProductData } from '../../services/product_api'
import { useState, useEffect } from 'react'
import { ProductData } from '../types'
import LRU from 'lru-cache'
import { getHash } from '../utils/Crypto'

export interface LoadOneProductOptions {
  material: string
  id: (string | number)[]
  ratio?: number
}

const cache = new LRU({max: 10})

export const useProductData = (options: LoadOneProductOptions) => {
  const [productData, setProductData] = useState<
    ProductData | undefined | null
  >(undefined)

  const signature = getHash(options)

  useEffect(() => {
    const cacheResult = cache.get(signature)

    if (typeof cacheResult !== 'undefined') {
      setProductData(cacheResult)
    } else {
      fetchProductData(options).then(response => {
        let result = null

        if (Array.isArray(response.data) && response.data.length >= 1) {
          result = response.data[0]
        } else {
          console.log('No Product Found')
        }

        cache.set(signature, result)
        setProductData(result)
      })
    }
  }, [signature])

  return productData
}
